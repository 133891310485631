@import 'index';

.icon {
  background-size: cover;
  display: inline-block;
  cursor: pointer;
}

.icon__wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  box-sizing: border-box;
  max-width: 20px;
  min-width: 20px !important;
  padding: 6px 0;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
}

.icon__wrapper__hover_enabled {
  @extend .icon__wrapper;
  &:hover {
    &::after {
      opacity: 1;
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      width: 24px;
      height: 24px;
      background-color: rgba(255, 255, 255, .05);
      border-radius: 4px;

    }
  }
}

.icon__wrapper__full_height {
  @extend .icon__wrapper;
  height: 100%;
}

.expand_arrow {
  background: url("../assets/images/icons/expand_arrow.svg");
  background-size: cover;
  width: 10px;
  height: 6px;
  cursor: pointer;
  transition: transform .3s ease;
}

.hamburger_menu {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 3.8%;
}

.hamburger_menu__icon {
  background: url("../assets/images/icons/hamburger_menu.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 14px;
  cursor: pointer;
}

.icon__add_lecture__rounded {
  @extend .icon;
  background: url('../assets/images/icons/add_rounded.svg');
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.icon__add_rounded__green {
  @extend .icon;
  background: url('../assets/images/icons/icon_add_green.svg');
  background-size: contain;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transition: box-shadow .2s ease;
  &:hover {
    box-shadow: 0 0 6px $greenMarker;
  }
}

.icon__close {
  @extend .icon;
  box-sizing: border-box;
  background: url('../assets/images/icons/close_icon.svg');
  margin-left: 7px;
  width: 14px;
  height: 14px;
}

.icon__delete {
  @extend .icon;
  background: url('../assets/images/icons/icon_delete_red.svg');
  height: 8px;
  width: 8px;
  .notification__subtitle_block & {
    padding-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon__x {
  @extend .icon;
  background: url('../assets/images/icons/x.svg');
  height: 20px;
  width: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  background-size: cover;
}

.icon__add_tag_btn {
  @extend .icon;
  width: 10px;
  height: 10px;
  background: url('../assets/images/icons/plus.svg');
}

.icon__close__invert {
  @extend .icon;
  height: 12px;
  width: 12px;
  background: url('../assets/images/icons/closeIcon_invert.svg');
  position: absolute;
  top: 18px;
  right: 12px;
}

.icon__calendar {
  @extend .icon;
  height: 12px;
  width: 12px;
  background: url('../assets/images/icons/calendar.svg');
}

.icon__search {
  @extend .icon;
  min-height: 12px;
  min-width: 12px;
  background: url('../assets/images/icons/icon_search.svg') no-repeat;
  background-size: cover;
}

.icon__big_calendar {
  background: url('../assets/images/icons/big_calendar.svg') no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.icon__groups{
  background: url('../assets/images/icons/icon_groups.svg') no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.icon__clock {
  background: url('../assets/images/icons/icon_clock.svg') no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
  margin-right: 12px;
}

.icon__notifications {
  background: url('../assets/images/icons/bell.svg') no-repeat;
  background-size: cover;
  width: 14px;
  height: 15px;
  margin-right: 12px;
}

.icon__requests {
  background: url('../assets/images/icons/requests.svg') no-repeat;
  background-size: cover;
  width: 14px;
  height: 17px;
  margin-right: 12px;
}

.icon__requests__blue {
  background: url('../assets/images/icons/icon_screen_blue.svg') no-repeat;
  background-size: contain;
  width: 14px;
  height: 12px;
  margin-right: 12px;
}

.icon__link_block {
  @extend .icon;
  background: url('../assets/images/icons/icon_link.svg') no-repeat;
  width: 19px;
  height: 18px;
  .container__link_block:hover & {
    background: url('../assets/images/icons/icon_link__active.svg') no-repeat;
  }
}

.icon__edit {
  cursor: pointer;
  background-size: cover;
  background: url('../assets/images/icons/icon_edit.svg') no-repeat;
  height: 11px;
  width: 12px;
}

.icon__file_preview {
  @extend .icon;
  margin-right: 14px;
  height: 18px;
  width: 18px;
  background: url('../assets/images/icons/file_preview.svg');
}

.icon__jpg {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/jpg.svg') no-repeat;
}

.icon__doc {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/doc.svg') no-repeat;
}

.icon__xls {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/xls.svg') no-repeat;
}

.icon__rar {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/rar.svg') no-repeat;
}

.icon__zip {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/zip.svg') no-repeat;
}

.icon__png {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/png.svg') no-repeat;
}

.icon__ppt {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/ppt.svg') no-repeat;
}

.icon__txt {
  @extend .icon;
  margin-right: 14px;
  height: 23px;
  width: 23px;
  background: url('../assets/images/icons/txt.svg') no-repeat;
}

.icon__download {
  background: url('../assets/images/icons/icon_download.svg') no-repeat;
  width: 12px;
  height: 11px;
  @extend .icon;
}

.icon__chart {
  background-size: cover !important;
  margin-right: 0;
  width: 16px;
  height: 16px;
  background: url('../assets/images/icons/icon_chart.svg') no-repeat;
}

.icon__map {
  background-size: cover !important;
  margin-right: 12px;
  width: 18px;
  height: 16px;
  background: url('../assets/images/icons/icon_map.svg') no-repeat;
}

.icon__expand {
  background: url('../assets/images/icons/icon_expand.svg') no-repeat;
  height: 6px;
  width: 10px;
  @extend .icon;
  transition: transform .4s ease-out;
  transform: rotate(0deg);
}

.icon__user {
  background: url('../assets/images/icons/icon_user.svg') no-repeat;
  width: 16px;
  height: 18px;
  margin-right: 12px;
  @extend .icon;
}

.icon__voting {
  background-size: cover;
  min-width: 16px;
  height: 16px;
  margin-right: 12px;
  background: url('../assets/images/icons/icon_voting.svg') no-repeat;
}

.icon__briefcase {
  background: url('../assets/images/icons/icon_briefcase.svg');
  background-size: cover;
  width: 17px;
  height: 16px;
  margin-right: 12px;
}

.icon__screen {
  background: url('../assets/images/icons/icon_monitor.svg');
  background-size: cover;
  width: 17px;
  height: 16px;
  margin-right: 12px;
}

.icon__plus {
  @extend .icon;
  height: 10px;
  width: 10px;
  margin-right: 25px;
  background-size: cover;
  background: url('../assets/images/icons/icon_plus.svg') no-repeat;
}

.icon__plus__green {
  @extend .icon;
  height: 10px;
  width: 10px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_green_plus.svg');
}

.icon__check_green {
  @extend .icon;
  height: 12px;
  width: 17px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_check_green.svg');
}

.icon__cpu_purple {
  @extend .icon;
  height: 11px;
  width: 11px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_cpu.svg');
}

.icon__waiting {
  @extend .icon;
  height: 10px;
  width: 10px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_clock_gray.svg');
}

.icon__cpu_green {
  @extend .icon;
  height: 11px;
  width: 11px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_cpu_green.svg');
}

.icon__logout {
  @extend .icon;
  height: 13px;
  width: 14px;
  background-size: cover;
  margin-right: 6px;
  margin-top: 1px;
  background-image: url('../assets/images/icons/icon_logout.svg');
}

.icon__star {
  @extend .icon;
  height: 10px;
  width: 10px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_star.svg');
}

.icon__stop_translation {
  @extend .icon;
  height: 13px;
  width: 13px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_stop_translation.svg');
}

.icon__big_star {
  @extend .icon;
  height: 13px;
  width: 13px;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_star.svg');
}

.icon_delete__gray {
  @extend .icon;
  background-size: cover;
  background-image: url('../assets/images/icons/icon_delete_gray.svg');
  width: 10px;
  height: 10px;
  position: relative;
  z-index: 2;
}

.ant-input-password-icon {
  color: $mainText;
  &:hover {
    color: $white;
  }
}

.icon__location {
  background-size: cover;
  background: url('../assets/images/icons/icon_location.svg') no-repeat;
  height: 13px;
  min-height: 13px;
  width: 11px;
  min-width: 11px;
  .card_list__item_address & {
    margin-right: 10px;
  }
}

.arrow_table {
  margin: -10px 10px 0 0;
  height: 40px;
  min-width: 25px;
  background-image: url('../assets/images/icons/arrow_table.svg');
  background-size: cover;
}

.icon_chart__power {
  width: 100%;
  background-size: contain;
  background: url('../assets/images/icons/icon_chart__power.svg') no-repeat center;
}

.icon_chart__heat {
  width: 100%;
  background-size: contain;
  background: url('../assets/images/icons/icon_chart__heat.svg') no-repeat center;
}

.icon__bar_chart {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_bar_chart.svg');
  background-size: cover;
}

.icon__bar_chart__active {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_bar_chart_active.svg');
  background-size: cover;
}

.icon__pie_chart {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_pie_chart.svg');
  background-size: cover;
}

.icon__pie_chart__active {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_pie_chart_active.svg');
  background-size: cover;
}

.icon__horizontal_bar_chart {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_bar_chart.svg');
  background-size: cover;
  transform: rotate(90deg);
}

.icon__horizontal_bar_chart__active {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_bar_chart_active.svg');
  background-size: cover;
  transform: rotate(90deg);
}

.icon_check {
  align-self: center;
  width: 10px;
  height: 7px;
  background: url('../assets/images/icons/icon__check.svg') no-repeat;
  background-size: contain;
  transition: opacity .2s ease;
}

.icon__play {
  width: 10px;
  height: 12px;
  margin-left: 2px;
  background: url('../assets/images/icons/icon_play_active.svg');
  background-size: cover;
  transition: opacity 0s;
  opacity: .15;
  .screen_list__item_check__active & {
    opacity: 1;
    transition: opacity .3s ease;
  }
}

.checked_marker {
  height: 20px;
  width: 20px;
  margin-left: 4px;
  border: .5px solid $cardListDivider;
  border-radius: 10px;
}

.icon__info {
  width: 22px;
  height: 22px;
  background: url('../assets/images/icons/icon_info.svg');
  background-size: cover;
  opacity: 0.5;
  z-index: 1;
  transition: opacity .3s ease;
  cursor: help;
  &:hover {
    opacity: 0.9;
  }
}

.icon__send {
  @extend .icon;
  width: 16px;
  height: 12px;
  background: url('../assets/images/icons/icon_send.svg') no-repeat;
  background-size: contain;
  margin-left: 3px;
}

.icon__check {
  width: 12px;
  height: 12px;
  & svg {
    & path {
      transition: stroke .1s ease;
      stroke: $secondaryText;
    }
    &:hover path {
      transition: stroke .1s ease;
      stroke: $blue;
    }
  }
}

.icon__check__active {
  width: 12px;
  height: 12px;
   svg {
    & path {
      transition: stroke .1s ease;
      stroke: $green;
    }
    &:hover path {
      transition: stroke .1s ease;
      stroke: $blue;
    }
  }
}

.input_suffix_btn:hover {
  & .icon__check,
  & .icon__check__active {
    & path {
      transition: stroke .1s ease;
      stroke: $blue;
    }
  }
}

.icon__qr {
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    & path {
      transition: stroke .1s ease;
      fill: $green;
    }
  }
}
