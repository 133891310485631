@import "constants";

.btn {
  font-family: 'DIN Pro';
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  user-select: none;
}

@mixin btn_transition($time) {
  -webkit-transition: box-shadow $time;
  -moz-transition: box-shadow $time;
  -o-transition: box-shadow $time;
  transition: box-shadow $time;
}

.bordered_btn {
  @include btn_transition(.5s);
  @extend .btn;
  padding: 0 19px;
  justify-content: center;
  min-height: 35px;
  height: 35px;
  border-radius: 18px;
  border: .5px solid $blue;
  z-index: 1;
  position: relative;
  background: $cardBG;
  outline: none;
  transition: box-shadow .2s ease-out, opacity .3s ease-out;
  &:hover {
    @include btn_transition(.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.5);
  }
  &:active {
    @include btn_transition(.2s);
    box-shadow: 0 0 2px rgba(0, 121, 193, 0.3);
  }
}

.bordered_btn__flex__end {
  @extend .bordered_btn;
  align-self: flex-end;
  transition: width .2s ease;
}

.btn_solid {
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  color: $white;
  background-color: $blue;
  border-radius: 18px;
  cursor: pointer;
  outline: none;
  @include btn_transition(.5s);
  &:hover {
    @include btn_transition(.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.5);
  }
  &:active {
    @include btn_transition(.2s);
    box-shadow: 0 0 2px rgba(0, 121, 193, 0.3);
  }
}

.bordered_btn__save_lecture {
  @extend .btn;
  @extend .bordered_btn;
}

.rounded_btn__save_event {
  @extend .btn;
  @extend .bordered_btn;
  @include btn_transition(.5s);
  background-color: $blue;
  color: $white;
  &:hover {
    @include btn_transition(.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.7);
  }
  &:hover {
    @include btn_transition(.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.7);
  }
}

.rounded_btn__green {
  @extend .btn;
  @extend .bordered_btn;
  width: fit-content !important;
  max-width: fit-content !important;
  border: .5px solid $green !important;
  background-color: $cardBG !important;
  color: $white;
  @include btn_transition(.5s);
  box-shadow: 0 0 0 rgba(49, 203, 129, 0.7);
  &:hover {
    @include btn_transition(.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(49, 203, 129, 0.7);
  }
}

.rounded_btn__red {
  @extend .btn;
  @extend .bordered_btn;
  border: .5px solid $red !important;
  background-color: transparent !important;
  color: $white;
  @include btn_transition(.5s);
  box-shadow: 0 0 0 rgba(205, 65, 65, 0.7);
  &:hover {
    @include btn_transition(.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(205, 65, 65, 0.7);
  }
}

.button_container {
  display: flex;
  justify-content: flex-end;
  border-top: .5px solid $divider;
  padding-top: 20px;
  margin-right: 27px;
}

.button_container__content_right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  max-height: 35px;
}

.button_container__voting {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.button_container__top_divider {
  padding-top: 15px;
  border-top: .5px solid $divider;
}

.large_btn__title {
  white-space: nowrap;
  color: $lightGray;
  line-height: 30px;
  user-select: none;
}

.large_btn__title__center {
  white-space: nowrap;
  color: $lightGray;
  line-height: 30px;
  user-select: none;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}

.simple_btn {
  @extend .btn;
  bottom: 20px;
  height: 35px;
  margin: 0 22px;
  font-size: $secondaryTextSize;
  & .large_btn__title {
    color: $blue;
    transition: color .2s ease-out;
    &:hover {
      transition: color .2s ease-out;
      color: #4cb6ff;
    }
  }
}

.bordered_btn__blue {
  @extend .bordered_btn;
}

.bordered_btn__red {
  @extend .bordered_btn;
  border-color: $lightRed;
  &:hover {
    box-shadow: 0 0 8px $semiTransparentRed;
  }
  &:active {
    box-shadow: 0 0 8px $semiTransparentRed;
  }
}

.btn_solid__green {
  @extend .btn_solid;
  font-family: 'DIN Pro';
  background-color: $green;
  &:hover {
    box-shadow: 0 0 8px $semiTransparentGreen;
  }
  &:active {
    box-shadow: 0 0 8px $semiTransparentGreen;
  }
}

.modal__button_wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: 30px;
  bottom: 30px;
  right: 30px;
}

.btn__chart {
  @extend .rounded_btn__green;
  @extend .align_self__center;
  margin-right: 20px;
}

.button_container__user_booking__content_right {
  @extend .button_container__content_right;
  align-self: flex-end;
  margin-top: 15px;
}

.on_air_switch__block {
  position: absolute;
  top: 19px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //height: inherit;
  min-width: 1px;
  overflow: visible;
}

.on_air_switch__btn {
  background-color: $onAirBtnWhiteBG;
  height: 26px;
  width: 34px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .2s ease-out;
}

.on_air_switch__btn__active {
  @extend .on_air_switch__btn;
  transition: background-color .3s ease-out;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  animation-name: pulse;
}

.on_air_switch__btn__focused {
  @extend .on_air_switch__btn;
  transition: background-color .3s ease-out;
  background-color: rgba(255, 255, 255, .4);
}

@-webkit-keyframes pulse {
  0% { background: #623434 }
  40% { background: #824a4a }
  100% { background: #623434 }
}

@keyframes pulse {
  0% { background: #623434 }
  40% { background: #824a4a }
  100% { background: #623434 }
}

.on_air_switch__btn_circle {
  background-color: $onAirBtnWhite;
  border-radius: 5px;
  cursor: pointer;
  height: 6px;
  width: 6px;
  transition: background-color .2s ease-out;
}

.on_air_switch__btn_circle__active {
  @extend .on_air_switch__btn_circle;
  background-color: $onAirBtnRed;
  transition: background-color .3s ease-out;
}

.on_air_switch__btn_circle__focused {
  @extend .on_air_switch__btn_circle;
}

.on_air_switch__title {
  display: inline-block;
  width: 69px;
  opacity: 0;
  font-family: "DIN Pro";
  margin-top: -50px;
  font-size: $mainTextSize;
  color: $onAirBtnRed;
  text-transform: uppercase;
  margin-right: 10px;
  user-select: none;
  overflow: hidden;
  transition: margin-top .2s ease-out, opacity .2s ease;
}

.on_air_switch__title__active {
  @extend .on_air_switch__title;
  margin-top: 0;
  opacity: 1;
  transition: margin-top .3s ease-out, opacity .2s ease;
}

.on_air_switch__title__focused {
  @extend .on_air_switch__title;
}

.chart_type_switch__block {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 34px;
  max-width: 34px;
  margin-left: 10px;
}

.chart_type_switch__button {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background-color: $lightBtnBG;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 6px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  transition: border .1s ease, background-color .1s ease, box-shadow .2s ease;
}

.chart_type_switch__button__active {
  @extend .chart_type_switch__button;
  background-color: $cardBG;
  border: 1px solid $green;
  box-shadow: 0 0 5px rgba(49, 203, 129, 0.7);
}

.btn_container__vote_form {
  @extend .button_container__top_divider;
  @extend .button_container__content_right;
  align-self: flex-end;
  flex: none;
  margin: 10px 17px 0 0;
  width: calc(100% - 20px);
  //max-width: calc(100% - 20px);
  padding-top: 0;
  max-height: unset;
  & .btn_solid {
    margin-top: 17px;
  }
}

.btn__add_notification {
  @extend .icon__add_rounded__green !optional;
  position: absolute;
  top: 24px;
  right: 24px;
}

.btn__send {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 14px;
  transition: background-color .4s ease, border .4s ease, opacity .4s ease;
}

.btn__send__inactive {
  @extend .btn__send;
  opacity: .6;
  background-color: transparent;
  border: 1px solid $white;
}

.btn__send__active {
  @extend .btn__send;
  opacity: 1;
  background-color: $blue;
  border: 1px solid transparent;
  cursor: pointer;
}

.auth__btn {
  @extend .button_container__content_right;
  max-height: unset;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  & .btn_solid {
    margin: 0;
    width: fit-content;
  }
}

.input_suffix_btn {
  background-color: $divider;
  border-width: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 0 4px 4px 0;
  align-items: center;
  justify-content: center;
  padding: 4px;
  &:last-child {
    border-left: .5px solid $lightBtnBG;
  }
}

.btn__stop_voting {
  @extend .bordered_btn;
  height: 34px;
  min-height: 34px;
  border: .5px solid $red;
  margin-left: 20px;
  &:hover {
    box-shadow: 0 0 8px $semiTransparentRed;
  }
  &:active {
    box-shadow: 0 0 2px $semiTransparentRed;
  }
}

.btn__reset_password {
  @extend .simple_btn;
  color: $secondaryText;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transition: color .2s ease;
  &:hover {
    color: $blue;
  }
}
