@import "constants";

.chart__title {
  font-family: 'DIN Pro';
  display: block;
  font-size: $mainTextSize;
  color: $mainText;
  width: 100%;
  text-align: center;
  min-height: 40px;
}

.chart__description {
  margin-left: 50px;
}

.chart__legend_block {
  @extend .flex_container;
  margin-top: 10px;
  &:first-child {
    margin-top: 20px;
  }
  display: flex;
  align-items: center;
}

.chart__inner_title {
  top: 48px;
  position: absolute;
  display: inline-block;
  transform: scaleX(-1);
  width: 100%;
  font-family: 'DIN Pro';
  font-size: 18px;
}

.chart__legend {
  display: block;
}

.chart__legend_title {
  display: block;
  font-size: $secondaryTextSize;
  color: $mainText;
}

.chart__legend_marker {
  background-color: $green;
  width: 40px;
  height: 12px;
  border-radius: 4px;
  margin-right: 15px;
}

.chart_block__vertical {
  @extend .flex_container__column;
  @extend .margin_top;
  @extend .flex_container__center;
  min-width: 120px;
}

.doughnut_chart__outer_circle {
  width: 100%;
  height: 120px;
  position: relative;
  transform: scaleX(-1);
}

.doughnut_chart__inner_circle {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 20px;
  transform: scaleX(-1);
}

.chart_line__small {
  display: flex;
  height: 250px;
}

.chartjs-render-monitor canvas {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

.round_chart__block {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 1px;
}

.partial_circle_chart__title {
  display: flex;
  transform: scaleX(-1);
  justify-content: left;
  align-items: flex-end;
  color: #D3D3D3;
  font-size: $smallTextSize;
  line-height: 15px;
  position: absolute;
  z-index: 2;
  height: 50%;
  width: 50%;
  left: 0;
  top: 0;
  padding: 0 0 14px 15px;
  text-align: right;
  & span {
    width: min-content;
  }
}

.partial_circle_chart__icon {
  @extend .flex_container;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.pie_chart__legend_item_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 6px 12px 12px;
  border-bottom: .5px solid $divider;
  width: 100%;
  user-select: none;
  cursor: default;
  & .round_marker {
    margin-right: 10px;
  }
  &:first-child {
    border-top: .5px solid $divider;
  }
}

.pie_chart__legend_block {
  @extend .flex_container__column;
  @extend .flex_container__center;
  width: 44%;
  margin-left: 7%;
}

.pie_chart__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
}

.pie_chart__chart_container {
  display: flex;
  width: 40%;
  padding-left: 3%;
}

.pie_chart__legend_block__content {
  width: 100%;
  padding-right: 7%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pie_chart__legend_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 2;
}

.pie_chart__legend_value {
  text-align: end;
  flex: 1;
  padding-right: 5px;
}

.group_bar_chart__chart_container {
  flex: 2;
  height: calc(100% - 31px);
  & .chartjs-render-monitor {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}

.group_bar_chart__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
}

.group_bar_chart__legend_block__content {
  @extend .flex_container;
  margin-top: 6px;
  justify-content: space-around;
  align-self: flex-end;
  width: 100%;
  flex: 1;
  height: 31px !important;
  max-height: 31px;
}

.group_bar_chart__legend_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: min-content;
}

.group_bar_chart__legend_block__marker {
  @extend .round_marker;
  margin-right: 8px;
}

.group_pie_chart__chart_container {
  display: flex;
  flex: 1;
  max-height: 50%;
  & .chartjs-render-monitor {
    height: 100% !important;
    width: min-content;
  }
}

.chartjs-size-monitor canvas,
.chartjs-render-monitor canvas {
  min-height: 1px !important;
  max-height: 100% !important;
  height: 100% !important;
}

.chart_block__wrapper {
  flex: 1;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.chart_vertical_bar__wrapper {
  @extend .chart_block__wrapper;
}

.chart_horizontal_bar__wrapper {
  @extend .chart_block__wrapper;
}
