@import "constants";

.ScrollbarsCustom-TrackY {
  width: 2px !important;
  height: 100% !important;
  top: 0 !important;
  background-color: $darkDivider !important;
}

.ScrollbarsCustom-ThumbY {
  width: 2px !important;
  background-color: $scrollThumb !important;
}

.ScrollbarsCustom-Wrapper {
  right: 0 !important;
  scroll-behavior: smooth;
}

.ScrollbarsCustom-Content {
  display: flex;
  flex-direction: column;
}
