@import 'constants';

.text__wrapper {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text__break_word {
  word-wrap: break-word;
}

.text__break_all {
  word-break: break-all;
}

.user_info_pop_up__name {
  font-size: 16px;
  overflow: hidden;
  max-height: 50px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.text__wrapper__two_lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text__uppercase {
  text-transform: uppercase;
}

.main_text {
  display: block;
  color: $mainText;
  font-size: $mainTextSize;
}

.main_text__white {
  line-height: 20px;
  display: block;
  color: $white;
  font-size: $mainTextSize;
}

.info__block__rights {
  @extend .main_text__white;
  margin-top: 4px;
  &:first-child {
    margin-top: 0;
  }
}

.text__no_wrap {
  wordBreak: 'break-word';
}

.main_text__subtitle {
  @extend .main_text__white;
  font-sixe: $subtitleSize;
  .user_info_pop_up & {
    @extend .text__wrapper;
  }
}

.main_text__link {
  font-family: 'DIN Pro';
  letter-spacing: .3px;
  text-transform: uppercase;
  display: inline-block;
  font-size: $mainTextSize;
  color: $blue;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  padding-bottom: 6px;
}

.main_text__link:after {
  bottom: 0;
  content: "";
  display: block;
  height: 1.1px;
  left: 50%;
  position: absolute;
  background: $blue;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.main_text__link:hover:after {
  width: 100%;
  left: 0;
}

.main_text__red {
  letter-spacing: .3px;
  display: block;
  font-size: $mainTextSize;
  color: $lightRed;
}

.main_text__green {
  @extend .main_text__red;
  color: $green;
}

.secondary_text__red {
  letter-spacing: .3px;
  display: block;
  font-size: $secondaryTextSize;
  color: $lightRed;
}

.secondary_text__green {
  letter-spacing: .3px;
  display: block;
  font-size: $secondaryTextSize;
  color: $green;
}

.small_text__secondary {
  display: block;
  color: $mainText;
  font-size: $secondaryTextSize;
}

.secondary_text {
  display: block;
  color: $mainText;
  font-size: $secondaryTextSize;
}

.secondary_text__inactive {
  display: block;
  color: $secondaryText;
  font-size: $secondaryTextSize;
}

.main_text__inactive {
  display: block;
  color: $secondaryText;
  font-size: $mainText;
}

.semi_secondary_text__gray {
  font-family: 'DIN Pro';
  display: block;
  font-size: $semiSecondaryTextSize;
  color: $mainText;
}

.semi_secondary_text__green {
  font-family: 'DIN Pro';
  display: block;
  font-size: $semiSecondaryTextSize;
  color: $green;
}

.secondary_text_subtitle {
  display: block;
  font-size: $smallTextSize;
  color: $subtitle;
}

.secondary_text__white {
  line-height: 20px;
  display: block;
  color: $white;
  font-size: $secondaryTextSize;
}

.notification__text {
  color: $mainText;
  font-size: $secondaryTextSize;
  cursor: default;
}

.form_label {
  color: $labelText;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
}

.indicator__voting_is_finished {
  @extend .secondary_text__inactive;
  line-height: 2;
  white-space: nowrap;
}
