@import "constants";

.card_list {
  padding: 3px 17px 3px 3px;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.card_list__item {
  height: 71px;
  min-height: 71px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: $topCardBG;
  padding: 15px 3%;
  box-sizing: border-box;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow .8s ease-out;
  &:first-child {
    margin-top: 2px;
  }
  & :nth-last-child(3) {
    border: 0;
    margin-right: 0;
  }
  &:hover {
    transition: box-shadow .3s ease-out;
    box-shadow: 0 0 6px $blue, 0 4px 8px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:active {
    transition: box-shadow .2s ease-out;
    box-shadow: 0 0 4px $blue, 0 0 8px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
}

.card_list__item__user__hover {
  @extend .card_list__item__small;
  @extend .cursor_default;
  & .card_list__item_description {
    border-right: 0px;
  }
  -webkit-animation: fade .4s;
  animation: fade .4s;
  animation-name: fade;
  transition: box-shadow .2s ease-out, background-color .2s ease;
  &:active {
    background-color: #181e28;
    transition: box-shadow .2s ease-out, background-color .2s ease;
  }
}

.card_list__item__user__no_hover {
  @extend .card_list__item__user__hover;
  @extend .card_list__item__no_hover;
  &:active {
    background-color: $topCardBG;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}

.card_list__item__small {
  @extend .card_list__item;
  height: 50px;
  min-height: 50px;
  user-select: none;
  overflow: hidden;
  & .card_list__item__group_title {
    line-height: normal;
  }
}

.card_list__item__active {
  @extend .card_list__item;
  transition: box-shadow .2s ease;
  box-shadow: 0 0 4px $blue;
  position: relative;
  z-index: 2;
}

.card_list__avatar {
  height: 40px;
  width: 40px;
  border-radius: 25px;
  background-color: $divider;
  background-size: cover;
  background-repeat: no-repeat ;
  background-position: center ;
}

.card_list__item_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: 2%;
}

.card_list__item_name {
  @extend .main_text;
  @extend .text__wrapper;
  flex: 3;
  height: 100%;
  line-height: 41px;
  padding-right: 2%;
  color: $subtitle;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_screen_name {
  @extend .card_list__item_name;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}

.card_list__item__group_title {
  @extend .main_text;
  @extend .text__wrapper;
  flex: 2;
  height: 100%;
  line-height: 41px;
  padding-right: 2%;
  color: $subtitle;
  border-right: 0.5px solid #2B3647 !important;
}

.card_list__item_access {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 1.3;
  margin: 0 2%;
  padding-right: 2%;
  text-align: center;
}

.card_list__item__users_count {
  @extend .text__wrapper;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1.5;
  margin: 0 2%;
  text-align: center;
  & .secondary_text__white {
    margin-left: 6px;
  }
}

.card_list__item_description {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 3;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: row;
  padding-right: 2%;
  margin-left: 2%;
  border-right: .5px solid $cardListDivider;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
    display: block;
  }
}

.card_list__item__user_description {
  @extend .card_list__item_description;
  margin-left: 0;
  padding-left: 2%;
  border-right: none;
  border-left: .5px solid $cardListDivider;
}

.card_list__item__presenter_description {
  @extend .card_list__item_description;
  border-right: none;
  display: block;
  height: auto;
}

.card_list__item_table_type {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 3;
  height: 100%;
  padding-right: 2%;
  display: block;
  margin-left: 1.2%;
  border-right: .5px solid $cardListDivider;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
    display: block;
  }
}

.card_list__item_address {
  @extend .secondary_text;
  flex: 2.5;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2%;
  margin-left: 2%;
  border-right: .5px solid $cardListDivider;
  & .secondary_text {
    @extend .text__wrapper__two_lines;
  }
}

.card_list__item_small_description {
  @extend .main_text__white;
  flex: .7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 2%;
  margin-left: 2%;
  border-right: .5px solid $cardListDivider;

}

.card_list__item_office_name {
  @extend .text__wrapper;
  height: 100%;
  line-height: 41px;
  color: $subtitle;
  font-size: $mainTextSize;
  flex: 3;
  margin-left: 2%;
  padding-right: 2%;
  text-align: left;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_colon_block {
  @extend .text__wrapper;
  font-size: $secondaryTextSize;
  display: flex;
  height: 100%;
  width: 15%;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  padding-right: 4%;
  text-align: left;
  border-right: .5px solid $cardListDivider;
  &:last-child {
    border: 0;
  }
  & div {
    display: flex;
    justify-content: flex-start;
    flex: 1;
    max-width: 50%;
  }
  & .secondary_text {
    line-height: 20px;
    margin-left: 14px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.card_list__item_colon_block__table {
  @extend .card_list__item_colon_block;
  flex: 5;
  align-items: flex-start;
  & .secondary_text__white {
    @extend .text__wrapper;
  }
}

.card_list__item__rating {
  flex: 1;
  display: flex;
  color: $mainText;
  align-items: center;
  justify-content: flex-start;
  margin: 0 2% 0 10px;
}

.card_list__item__colon {
  flex: 5;
  display: flex;
  height: 100%;
}


.card_list__item_id {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: .3;
  max-width: 15%;
  min-width: 34px;
  display: block;
  align-items: center;
  justify-content: flex-start;
  margin: 0 2% 0 5px;
  .card_list__item_marker + & {
    flex: .1;
  }
}

.card_list__item_marker {
  flex: .08;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 2% 0 5px;
  max-width: 6px;
}

.card_list__item_title {
  @extend .text__wrapper;
  @extend .main_text;
  color: $subtitle;
  height: 100%;
  line-height: 41px;
  flex: 8;
  padding-right: 2%;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_place {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: 2.5;
  height: 100%;
  line-height: 41px;
  margin-left: 2%;
  padding-right: 2%;
  border-right: .5px solid $cardListDivider;
}

.card_list__item_is_over {
  @extend .card_list__item_place;
  flex: 1;
  padding-left: 4%;
  border-right: 0;
  border-left: .5px solid $cardListDivider;
}

.card_list__item_time {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: 1.2;
  justify-content: center;
  margin: 0 2%;
}

.card_list__item_date {
  @extend .text__wrapper;
  @extend .secondary_text;
  flex: .8;
  justify-content: center;
  margin: 0 2%;
}

.card_list__item_voting_date {
  @extend .card_list__item_date;
  flex: 1.2;
}

.card_list__item_duration {
  display: flex;
  flex-direction: row;
  min-width: 145px;
  flex: 2.5;
  border-right: .5px solid $cardListDivider;
  margin: 0 2%;
  padding-right: 3%;
  justify-content: center;
  & .main_text {
    @extend .text__wrapper;
    display: block;
  }
}

.card_list__item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.card_list_item__reserve {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: $topCardBG;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-bottom: 3px;
  cursor: pointer;
  transition: box-shadow .8s ease-out;
  .container__floor & {
    margin-bottom: 9px;
  }
  &:first-child {
    margin-top: 2px;
  }
  &:hover {
    transition: box-shadow .3s ease-out;
    box-shadow: 0 0 6px $blue, 0 4px 10px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:active {
    transition: box-shadow .2s ease-out;
    box-shadow: 0 0 4px $blue, 0 0 8px $semiTransparentBlack;
    position: relative;
    z-index: 2;
  }
  &:last-child {
    margin-bottom: 2px;
  }
  & h1 {
    @extend .text__wrapper;
    display: flex;
    justify-content: center;
    flex: 4;
  }
}

.card_list_item__reserve__active {
  @extend .card_list_item__reserve;
  transition: box-shadow .2s ease;
  box-shadow: 0 0 4px $blue;
  position: relative;
  z-index: 2;
}

.card_list__item__presenter_status_block {
  display: flex;
  flex: 1;
  min-width: 130px;
  justify-content: flex-end;
  align-items: center;
  transition: width .2s ease, border-radius .2s ease, min-width .2s ease;
  & div {
    transition: width .2s ease, border-radius .2s ease, min-width .2s ease;
  }
}

.card_list__item__presenter_stop_btn {
  display: flex;
  flex: 1;
  margin-right: 14px !important;
  justify-content: center;
}

.card_list__item__presenter_status {
  padding: 0 13px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  color: $white;
  width: min-content;
  background-color: $lightRed;
  white-space: nowrap;
  cursor: pointer;
  transition: box-shadow .2s ease;
  &:hover {
    box-shadow: 0 0 6px rgba(218, 82, 82, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(218, 82, 82, .5);
  }
}

.card_list__item__presenter_status__active {
  @extend .card_list__item__presenter_status;
  background-color: $green;
  &:hover {
    box-shadow: 0 0 6px rgba(49, 203, 129, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(49, 203, 129, .5);
  }
}

.card_list__item__presenter_status__waiting {
  @extend .card_list__item__presenter_status;
  background-color: $secondaryText;
  &:hover {
    box-shadow: 0 0 6px rgba(122, 125, 130, .5);
  }
  &:active {
    box-shadow: 0 0 4px rgba(122, 125, 130, .5);
  }
}

.card_list__item__no_hover {
  box-shadow: none !important;
  & .card_list__item_id {
    margin-right: 2%;
  }
}

// <-- DoubleList -->

.card_list__small {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  width: 100%;
  padding: 0 17px 0 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.card_list__small__wrapper {
  padding: 0 10px 0 2px;
}

.card_list__small_container__title {
  @extend .secondary_text_subtitle;
  @extend .text__uppercase;
  padding: 0 0 4px 10px;
  user-select: none;
  text-transform: uppercase;
}
